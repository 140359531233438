import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/nextjs-client-side-server-side-and-static-rendering",
  "date": "2021-02-02",
  "title": "NextJS Client-side, Server-side & Static Rendering",
  "author": "admin",
  "tags": ["development", "javascript", "nextjs", "ssr"],
  "featuredImage": "feature.jpg",
  "excerpt": "The future of SEO and search ranking algorithms are now in heavy favour of static or server-side rendering. This means building static or server-side rendered apps instantly gain advantages in rankings."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`In this article, we are going to discuss building static & server-side rendering apps with NextJS, a server-side rendering React framework.`}</p>
    <h2>{`What is Static Rendering & Server-Side Rendering`}</h2>
    <h3>{`Server-Rendering (SSR)`}</h3>
    <p>{`In response to each request, renders the app on the server, and then sends the rendered HTML and Javascript back to the client.`}</p>
    <h3>{`Client-Side Rendering (CSR)`}</h3>
    <p>{`Renders the app in the browser on the client at run time.`}</p>
    <h3>{`Static Rendering (SR)`}</h3>
    <p>{`Usually generating a static HTML page for every URL. Pre-built app, or renders the app at build time (e.g. when you run the `}<inlineCode parentName="p">{`npm run build`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn build`}</inlineCode>{` command).
This is the default approach of NextJS.`}</p>
    <h2>{`NextJS`}</h2>
    <p>{`In NextJS, there are three main functions that are used for SSR and SR, and a hook that for client-side data fetching.`}</p>
    <p>{`Built time:`}</p>
    <p><strong parentName="p">{`getStaticProps`}</strong>{` — fetches data at build time
`}<strong parentName="p">{`getStaticPaths`}</strong>{` — pre-render dynamic routes at build time`}</p>
    <p>{`Run time:`}</p>
    <p><strong parentName="p">{`getServerSideProps`}</strong>{` — fetches data on each request
`}<strong parentName="p">{`SWR`}</strong>{` — fetches data on the client-side at run time`}</p>
    <h3>{`Server-Side Rendering (SSR)`}</h3>
    <p>{`The HTML is generated on the server on each request for the page — the HTML is 'dynamic' rather than 'static' as it will depend on the data required.`}</p>
    <p>{`Each time a client requests the page, the server will fetch the data for that page, and generate the page's HTML using the data.`}</p>
    <p>{`Next.js offers two data fetching methods for SSR — `}<strong parentName="p">{`getServerSideProps`}</strong>{` and `}<strong parentName="p">{`getInitialProp`}</strong></p>
    <p><img parentName="p" {...{
        "src": "/images/2021-02-02-nextjs-client-side-server-side-and-static-rendering/rendering-web.png",
        "alt": "Rendering web"
      }}></img></p>
    <p>{`In order to prevent the situation where data fetching can happen on both server and client-side, it is preferred to use `}<strong parentName="p">{`getServerSideProps`}</strong>{` over `}<strong parentName="p">{`getInitialProp`}</strong>{`.`}</p>
    <h4>{`getServerSideProps`}</h4>
    <p>{`NextJS will render pages using the `}<strong parentName="p">{`getServerSideProps`}</strong>{` function on each request and will use the data returned by the function to populate the components props.`}</p>
    <Message type="info" title="Non build time API calls" content="You should only use getServerSideProps if you can’t access the data needed for the page at build time. i.e. the request has some piece of information that you need to fetch the required data or to render the page." mdxType="Message" />
    <p>{`Note that getServerSideProps ONLY runs on the Server, and is never downloaded to or run in the Client.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const Page = ({ data }) => <div>{data}</div>;

export const getServerSideProps = async () => {
  const res = await fetch('https://path/to/your/api');
  const data = await res.json();
  return { props: { data } };
};
export default Page;
`}</code></pre>
    <p>{`Pros`}</p>
    <ul>
      <li parentName="ul">{`Rendered page will always be up-to-date`}</li>
      <li parentName="ul">{`Can handle dynamic routes on the fly`}</li>
    </ul>
    <p>{`Cons`}</p>
    <ul>
      <li parentName="ul">{`Not possible to be cached by a CDN`}</li>
      <li parentName="ul">{`Can be slow if there is heavy processing to do since the client has to wait for each page after it makes a request`}</li>
    </ul>
    <h3>{`Static Rendering (SR)`}</h3>
    <p>{`Renders the app at build time (e.g. when you run the npm run build command). Generally involves generating a static HTML page for every URL. This is NextJS’s default approach.`}</p>
    <h4>{`getStaticProps`}</h4>
    <p>{`Next.JS pre-renders each page at build time and uses the props returned by getStaticProps to hydrate the page with data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const App = ({ data }) => <p>{data}</p>;

export const getStaticProps = async () => ({ props: { data: 'Hello world' } });
`}</code></pre>
    <p>{`A few things about `}<strong parentName="p">{`getStaticProps`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Since it executes at build time, it does not receive data from any request that gets made at run time. This includes things like HTTP headers, query params etc`}</li>
      <li parentName="ul">{`It ONLY runs on the Server, meaning that you can write server code here and it won’t be downloaded to or run on the Client, e.g. API calls, database calls, requests, etc`}</li>
      <li parentName="ul">{`You don’t need to make local API calls, as it only runs on the Server, (e.g. /pages/api). Just write the server code directly inside of `}<strong parentName="li">{`getStaticProps`}</strong>{`.`}</li>
    </ul>
    <h4>{`getStaticPaths`}</h4>
    <p>{`This function allows you to make a list of dynamic routes that you expect to need later on, and then render them at build time (i.e. when you run npm run build in your terminal window).
You define them by returning an object with the key of paths (required).`}</p>
    <p>{`The returned object also has a key called `}<strong parentName="p">{`fallback`}</strong>{` (required) which dictates this behavior.`}</p>
    <p>{`If you set the `}<strong parentName="p">{`fallback`}</strong>{` key to false, then all non-defined routes 404 out.`}</p>
    <p>{`If you set the fallback key to true, then when a User requests a non-defined page, NextJS throws up fallback HTML that you have defined in the same file
(you just detect the fallback by using if (router.isFallback)) and in the background Next goes and fully generates the page being requested using the URL
that the User entered, and then swaps it out for the fallback HTML.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useRouter } from 'next/router';

const Post = ({ post }) => {
  const router = useRouter();

  if (router.isFallback) {
    return <div>Loading...</div>;
  }

  return <div>{...post}</div>;
};

export async function getStaticPaths() {
  return {
    paths: [{ params: { id: '1' } }, { params: { id: '2' } }],
    fallback: true,
  };
}

export async function getStaticProps({ params }) {
  // if url was /posts/2, params.id will be 2
  const res = await fetch(\`https://path/to/your/api/\${params.id}\`);
  const post = await res.json();

  return { props: { post } };
}

export default Post;
`}</code></pre>
    <p>{`Pros`}</p>
    <ul>
      <li parentName="ul">{`Consistently very fast and performant`}</li>
    </ul>
    <p>{`Cons`}</p>
    <ul>
      <li parentName="ul">{`Cannot handle dynamic routes if you don’t know all the URL’s ahead of (build) time`}</li>
    </ul>
    <h3>{`Client-Side Rendering (CSR)`}</h3>
    <p>{`Renders the app on the Client in the browser at run time.`}</p>
    <p>{`Pros`}</p>
    <ul>
      <li parentName="ul">{`Nothing that stands out over the other options`}</li>
    </ul>
    <p>{`Cons`}</p>
    <ul>
      <li parentName="ul">{`Slows down as the application grows, and should generally be avoided`}</li>
    </ul>
    <Message type="info" title="SSR, SR, and CSR" content="NextJS on server-side, will render as much HTML with data as it can at build time with `getStaticProps`, and then if it needs data later on, it will run `getServerSideProps`." mdxType="Message" />
    <p>{`If you need to fetch data on the client-side, you can use `}<inlineCode parentName="p">{`SWR`}</inlineCode>{`.`}</p>
    <h4>{`The SWR Hook`}</h4>
    <p>{`Client-side rendering is something that should avoid when possible. But when it is required, you can call the SWR hook, to be called at runtime. It stand for `}<strong parentName="p">{`Stale While Revalidate`}</strong>{`.`}</p>
    <Message type="info" title="Stale While Revalidate (SWR)" content="This is the name of a HTTP cache invalidation strategy." mdxType="Message" />
    <p>{`Here's an example on how to use SWR.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import useSWR from 'swr';

const Post = () => {
  const { data, error } = useSWR('/api/post/123', fetch);

  if (error) {
    return <div>Error loading post!</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return <div>{data}</div>;
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      